import React, {useState, useEffect} from 'react'
import {graphql, Link} from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { repositoryConfigs } from '../utils/prismic-previews'
import { RichText } from 'prismic-reactjs'
import { useForm } from "react-hook-form";
import ReactRecaptcha3 from 'react-google-recaptcha3';
import { SliceZones } from '../components/slices/slice-zones'
import Layout from '../components/global/layout'
import BlogRelatedPostsSidebar from '../components/blog/related-posts-sidebar'
import CustomLink from '../utils/prismic-content-link'
import BlogHeader from '../components/blog/header'
import {pages, siteMetadata} from '../site-config'


const OurInsightArticle = ({ data, pageContext }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const [state, setState] = useState({
        isSubmitted: false,
        isError: false
      });

    useEffect(() => {
        ReactRecaptcha3.init('6Lf8Y-ogAAAAAHK0AtJ6H4DN-KvX3gbgewzgF8bW').then(status => {
            // status: success/error
            // success - script is loaded and greaptcha is ready
            // error - script is not loaded
            // console.log(status)
        });
    }, []);

    const onSubmit = (data) => {
        ReactRecaptcha3.getToken().then(token => {
            setState({
                isSubmitted: true,
            });
            // console.log(token)
        }, error => {
            setState({
                isError: true,
            });
            // console.log(error)
        })
    }

    if (!data)
        return null

    const document = data.prismicInsightArticle
    const documentsByTag = data.allPrismicInsightArticle.nodes
    const author = document.data.author.document !== null && document.data.author.document !== undefined ? document.data.author.document.data : null

    const pageUrl = `${siteMetadata.siteUrl}${document.url}`

    return (
    <Layout pageTitle={document.data.title.text}
            metaTitle={document.data.meta_title.text}
            metaDescription={document.data.meta_description.text || document.data.summary.text}
            shareImage={document.data.share_image.url}>
      <div className="c-article">
        <div className="container">
            <div className="row">
                <div className="c-article__main">
                    <div className="c-article__header">
                        <h1 className="c-article__header-title">{document.data.title.text}</h1>
                        <BlogHeader author={author} postDate={document.data.date} />
                    </div>
                    <div className="c-article__body cms-content">
                      <RichText render={document.data.content.raw} serializeHyperlink={CustomLink} />
                    </div>
                </div>
                <div className="c-article__sidebar">
                    <div className="c-article__subscribe">
                        <div className="c-article__subscribe-title" id="share-label">Subscribe</div>
                        {!state.isSubmitted ?
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="c-contact__form-fields">
                                    <div className="c-contact__form-field">
                                        <div className="c-form__textbox-field">
                                            <label htmlFor="email" className="c-form__label">Email</label>
                                            <input id="email" name="email" placeholder="Email" className={errors.contactemail ? "c-form__textbox error" : "c-form__textbox"} {...register("email", { required: true, pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/ })} />
                                            {errors.email && <div className="c-form__validation c-form__validation--error"><p>Please enter a valid email</p></div>}
                                        </div>
                                        <br/>
                                        <div className="c-contact__form-field">
                                            <p className="c-contact__form-consent">By clicking the 'Subscribe' button you're confirming that you agree with our following <Link to={pages.privacyPolicy}>Privacy Policy</Link>.</p>
                                        </div>
                                        <div className="c-contact__form-field">
                                            <input type="submit" value="Subscribe" className="c-contact__submit c-btn c-btn--large c-btn--primary" />
                                        </div>
                                    </div>
                                </div>
                                {state.isError ? <p className="c-contact__summary">Unfortunately, your submission could not be sent. Please try again later.</p> : null}
                            </form>
                            : <p className="c-contact__summary">Thank you for subscribing to our insights.</p>}
                    </div>
                    <BlogRelatedPostsSidebar posts={documentsByTag}  />
                </div>
            </div>
        </div>
      </div>
      <SliceZones slices={document.data.body} />
    </Layout>
    )
}

export const query = graphql`
  query OurInsightArticleQuery($id: String, $tags: [String]) {
    prismicInsightArticle(id: { eq: $id }) {
      _previewable
      id
      uid
      lang
      type
      url
      data {
        title {
          text
        }
        summary {
          text
        }
        date
        content {
          raw
        }
        meta_title {
          text
        }
        meta_description {
          text
        }
        share_image {
          url
        }
        author {
          document {
            ... on PrismicAuthor {
              id
              data {
                name {
                  text
                }
                linkedin_url {
                  url
                }
                email_address {
                  text
                }
                image {
                  url
                }
              }
            }
          }
        }
        body {
          ... on PrismicInsightArticleDataBodyLogos {
            id
            items {
              logos_image {
                alt
                copyright
                url
                gatsbyImageData
              }
              logos_name {
                text
                html
                raw
              }
            }
            primary {
              logos_title {
                text
              }
              logos_background_image {
                url
              }
            }
            slice_type
          }
          ... on PrismicInsightArticleDataBodyContentWithMedia {
            id
            primary {
              contentmedia_subtitle {
                text
              }
              contentmedia_title {
                text
              }
              contentmedia_content {
                text
              }
              contentmedia_image {
                url
              }
              contentmedia_button_text {
                text
              }
              contentmedia_button_link {
                url
              }
              contentmedia_button2_text {
                text
              }
              contentmedia_button2_link {
                url
              }
              contentmedia_image_alignment
            }
            slice_type
          }
          ... on PrismicInsightArticleDataBodyCallToAction {
            id
            primary {
              cta_button_link {
                url
              }
              cta_button_text
              cta_description {
                text
              }
              cta_title {
                text
              }
            }
            slice_type
          }
          ... on PrismicInsightArticleDataBodyRichTextContent {
            id
            primary {
              richtextcontent_content {
                raw
              }
            }
            slice_type
          }
          ... on PrismicInsightArticleDataBodyTestimonial {
            id
            primary {
              testimonial_quote {
                text
              }
              testimonial_link_text {
                text
              }
              testimonial_link {
                url
              }
            }
            slice_type
          }
          ... on PrismicInsightArticleDataBodyWhyWorkWithUs {
            id
            primary {
              whyworkwithus_point1 {
                text
              }
              whyworkwithus_point2 {
                text
              }
              whyworkwithus_point3 {
                text
              }
              whyworkwithus_title {
                text
              }
            }
            slice_type
          }
          ... on PrismicInsightArticleDataBodyUseCases {
            id
            items {
              usecases_usecase {
                text
              }
            }
            primary {
              usecases_link_text {
                text
              }
              usecases_title {
                text
              }
              usescases_link {
                url
              }
            }
            slice_type
          }
          ... on PrismicInsightArticleDataBodyTestimonialCarousel {
            id
            items {
              testimonialcarousel_author {
                text
              }
              testimonialcarousel_authorjobtitle {
                text
              }
              testimonialcarousel_image {
                url
              }
              testimonialcarousel_quote {
                text
              }
              testimonialcarousel_title {
                text
              }
            }
            primary {
              testimonialcarousel_title {
                text
              }
            }
            slice_type
          }
          ... on PrismicInsightArticleDataBodyHowItWorksCarousel {
            id
            primary {
              howitworkscarousel_title {
                text
              }
            }
            items {
              howitworkscarousel_title {
                text
              }
              howitworkscarousel_summary {
                text
              }
            }
            slice_type
          }
          ... on PrismicInsightArticleDataBodyFeaturedProjects {
            id
            primary {
              featuredprojects_startprojectlink {
                url
              }
              featuredprojects_title {
                text
              }
              display_tag
            }
            items {
              featuredprojects_document {
                document {
                  ... on PrismicProject {
                    id
                    data {
                      image {
                        url
                      }
                      summary {
                        text
                      }
                      title {
                        text
                      }
                      industry
                      service
                    }
                    tags
                  }
                }
              }
            }
            slice_type
          }
          ... on PrismicInsightArticleDataBodyServicesCarousel {
            id
            items {
              servicescarousel_image {
                url
              }
              servicescarousel_circleimage {
                url
              }
              servicescarousel_fullscreenimage {
                url
              }
              servicescarousel_link {
                url
              }
              servicescarousel_navigationtitle {
                text
              }
              servicescarousel_summary {
                text
              }
              servicescarousel_title {
                text
              }
            }
            primary {
              servicescarousel_subtitle {
                text
              }
              servicescarousel_title {
                text
              }
              servicescarousel_summary {
                text
              }
            }
            slice_type
          }
          ... on PrismicInsightArticleDataBodyFeaturedBlogPosts {
            id
            items {
              featuredblogposts_document {
                document {
                  ... on PrismicInsightArticle {
                    id
                    data {
                      listing_image {
                        url
                      }
                      title {
                        text
                      }
                      summary {
                        text
                      }
                    }
                    url
                    tags
                  }
                }
                type
              }
            }
            primary {
              featuredblogposts_title {
                text
              }
            }
            slice_type
          }
          ... on PrismicInsightArticleDataBodyListing {
            id
            primary {
              listing_title {
                text
              }
              listing_buttonlink {
                url
              }
              listing_buttontext {
                text
              }
              listing_button2link {
                url
              }
              listing_button2text {
                text
              }
            }
            items {
              image {
                url
              }
              listing_title {
                text
              }
            }
            slice_type
          }
        }
      }
    }
    allPrismicInsightArticle (
      sort: {fields: data___date, order: DESC}
      limit: 10
      filter: {tags: {in: $tags}, id: {ne: $id}}
    ) {
      nodes {
        data {
          summary {
            text
          }
          title {
            text
          }
          date
          listing_image {
            alt
            url
          }
        }
        url
        tags
      }
    }
  }
`

export default withPrismicPreview(OurInsightArticle, repositoryConfigs)